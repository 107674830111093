* {
  margin: 0;
  padding: 0;
}

.fullscreen {
  background-color: #fafafa;
  padding-bottom: 64px;
}

.Header {
  height: 56px;
  width: 100%;
  box-sizing: border-box;
  background: #009688;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  justify-content: space-between;
}

.elements {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.elements > h5 {
  font-size: 20px;
  color: #fafafa;
  font-weight: 500;
  margin: 18px;
  font-family: "Arimo", sans-serif;
}

.form {
  align-items: center;
  padding: 24px;
  box-sizing: border-box;
}

.form input {
  width: 100%;
}

#email {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px;
  box-sizing: border-box;
  line-height: 48px;
  background: #fff;
  border-radius: 2px;
  margin-bottom: 35px;
  position: relative;
  border-color: transparent;
  color: #868f8b;
}

#name {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px;
  box-sizing: border-box;
  line-height: 48px;
  background: #fff;
  border-radius: 2px;
  margin-bottom: 35px;
  position: relative;
  border-color: transparent;
  color: #868f8b;
}

#number {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px;
  box-sizing: border-box;
  line-height: 48px;
  background: #fff;
  border-radius: 2px;
  margin-bottom: 35px;
  position: relative;
  border-color: transparent;
  color: #868f8b;
}
#password {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px;
  box-sizing: border-box;
  line-height: 48px;
  background: #fff;
  border-radius: 2px;
  margin-bottom: 35px;
  position: relative;
  border-color: transparent;
  color: #868f8b;
}

#Code {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px;
  box-sizing: border-box;
  line-height: 48px;
  background: #fff;
  border-radius: 2px;
  margin-bottom: 35px;
  position: relative;
  border-color: transparent;
  color: #868f8b;
}

#login {
  width: 240px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  background: #009688;
  font-size: 14px;
  font-family: "Arimo", sans-serif;
  color: #fff;
  margin-top: 2%;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
}

#register {
  padding: 0 15px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #f5f5f5;
  margin-top: 2%;
  font-size: 14px;
  font-family: "Arimo", sans-serif;
  color: rgba(0, 0, 0, 0.87);
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
}
#ForgotPassword {
  padding: 0 15px;
  height: 40px;
  text-align: center;
  background: #f5f5f5;
  margin-top: 2%;
  font-size: 14px;
  font-family: "Arimo", sans-serif;
  color: rgba(0, 0, 0, 0.87);
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
}

input {
  font-family: "Arimo", sans-serif;
}

label {
  font-family: "Arimo", sans-serif;
}

button {
  font-family: "Arimo", sans-serif;
}

.buttons {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.btn {
  display: flex;
  margin-top: 2%;
  width: 240px;
  justify-content: space-between;
}

.footer {
  width: 100%;
  height: 50px;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.list {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 0px !important;
}

.list li {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Arimo", sans-serif;
}

.list li span {
  color: #868f8b;
  font-size: 12px;
  margin-top: 3px;
}

.specialbox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inputbox {
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px;
  box-sizing: border-box;
  line-height: 48px;
  background: #fff;
  border-radius: 2px;
  margin-bottom: 35px;
  position: relative;
}

.otpcode {
  width: 26%;
  height: 46px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px;
  line-height: 46px;
  border-radius: 2px;
  margin-bottom: 24px;
  border: 0;
  text-align: center;
  font-family: "Arimo", sans-serif;
}

.box_btn {
  padding: 15px 0 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.regi_btn {
  width: 65%;
  height: 44px;
  text-align: center;
  line-height: 44px;
  background: #009688;
  font-size: 14px;
  font-family: "Arimo", sans-serif;
  color: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
}

.green {
  color: #009688;
  font-family: "Arimo", sans-serif;
}

.search {
  padding: 0;
  margin: 0;
}

.search_box {
  background: #fafafa;
  padding: 20px 15px 0 15px;
  box-sizing: border-box;
}

.search_box input {
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  height: 48px;
  font-size: 14px;
  line-height: 48px;
  padding: 0 12px;
  box-sizing: border-box;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  border-radius: 2px;
  border: 0;
}

.item_list {
  width: 100%;
  padding: 20px 0 64px 0;
  box-sizing: border-box;
  background: #fafafa;
}

.list_content {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.list_ul {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}

.list_li {
  width: 50%;
  padding: 4px;
  box-sizing: border-box;
}

.list-ul .list_li ol {
  background-color: #fff;
  border-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  overflow: hidden;
}

.list_img_box {
  width: 100%;
  height: 200px;
  padding: 8px;
  box-sizing: border-box;
}

.description {
  height: 58px;
  overflow: hidden;
  padding: 8px;
  font-size: 14px;
  box-sizing: border-box;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Arimo", sans-serif;
}

.info {
  -webkit-line-clamp: 3;
}

.price {
  display: inline-block;
  color: #f39839;
  padding: 8px;
  font-size: 14px;
  font-family: "Arimo", sans-serif;

  box-sizing: border-box;
}

.list_ul .list_li ol {
  background-color: #fff;
  border-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  overflow: hidden;
  padding: 0;
}

.home {
  margin: 0;
  padding: 0;
}

.nav {
  height: 56px;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #eee;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  will-change: padding-left, padding-right;
  position: relative;
}

.logo img {
  width: 36px;
  height: 36px;
}

span {
  font-family: "Arimo", sans-serif;
}

.nav span {
  display: inline-block;
  width: 74%;
  color: #616161;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.02em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.download {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_title {
  background: #fafafa;
}

.top {
  text-align: center;
  padding: 12px 12px 0;
  color: #009688;
  font-size: 28px;
  margin: 0;
  font-family: "Arimo", sans-serif;
}

.bot {
  text-align: center;
  padding: 0 12px 16px;
  color: #9a9a9a;
  font-size: 16px;
  margin: 0;
  font-family: "Arimo", sans-serif;
}

.swipe {
  height: 500px;
  width: 100%;
}

.sl-swipe {
  position: relative;
  overflow: hidden;
  cursor: grab;
  /* -webkit-user-select: none; */
  user-select: none;
}

.sl-swipe_track {
  height: 100%;
  display: flex;
}

/* .back_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
} */

.back_img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}

.my {
  padding-bottom: 64px;
  background: #fafafa;
}

.my-top {
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  background: #fff;
}

.my-info {
  width: 100%;
  background: #009688;
  border-radius: 2px;
  padding: 12px 8px 8px 8px;
  box-sizing: border-box;
}

.my-info-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.my-info-items {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  font-size: 16px;
  padding: 20px 0;
}

.info-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.info-left img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #424242;
  margin: 0 5px 0 13px;
  border: 0;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12) !important;
}

.info-left ul {
  color: #fff;
  font-size: 14px;
  margin-left: 15px !important;
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: "Arimo", sans-serif;
}

.info-left ul li {
  line-height: 22px;
}

.notice {
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notice img {
  width: 20px;
  height: 20px;
}

.top-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-item > div {
  margin-bottom: 5px;
  font-family: "Arimo", sans-serif;
}

.top-item > button {
  border-color: #2196f3 !important;
  padding: 0 8px;
  margin-top: 5px;
  width: 80px;
  font-size: 14px;
  font-family: "Arimo", sans-serif;
}

.one-btn {
  background: #2196f3 !important;
  color: #fff !important;
  font-family: "Arimo", sans-serif;
}

.rpl {
  position: relative;
  overflow: hidden;
}

.my-nav {
  padding: 8px 0;
  background: #fff;
  margin-top: 2px;
}

.my-nav ul {
  list-style: none;
}

.my-nav ul li {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  box-sizing: border-box;
  /* border-width: 1px; */
  cursor: pointer;
}

.my-nav ul li ol {
  display: flex;
  align-items: center;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.logout {
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  font-family: "Arimo", sans-serif;
}

.logout button {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background: #f5f5f5;
  border-radius: 2px;
  font-size: 14px;
  font-family: "Arimo", sans-serif;
  height: 36px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.87);
  border: 0;
  width: 50%;
}

.ReactModal__Overlay {
  /* Style for the modal backdrop */
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReactModal__Content {
  /* Style for the modal content */
  background: #fff;
  /* padding: 15px; */
  box-sizing: border-box;
  border-radius: 4px;
  width: 90%;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.ReactModal__Content--after-open {
  position: initial !important;
  padding: 0px !important;
}

.btn-close {
  display: flex;
  justify-content: flex-end;
  padding: 8px 5px 8px 15px;
  box-sizing: border-box;
}

.btn-close button {
  color: #00897b;
  font-size: 14px;
  padding: 5px 10px;
  border: 0;
  background: transparent;
  margin-left: 10px;
}

.ttl {
  font-size: 24px;
  padding: 15px;
}

.sign-in-info {
  margin-bottom: 10px;
}

.sign-info {
  padding: 10px 0 10px 20px;
}

.cancel {
  color: rgb(136, 136, 136) !important;
}

/* My Order Page */

.top-box {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.top-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  width: 100%;
  line-height: 56px;
  padding: 0 15px;
  box-sizing: border-box;
  background: #009688;
  justify-content: space-between;
}

.left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon1 {
  width: 20px;
  height: 20px;
  margin-right: 30px;
  color: #fff;
}

.left span {
  color: #fff;
  font-size: 20px;
  font-family: "Arimo", sans-serif;
  font-weight: 500;
  letter-spacing: 0.02em;
}

.order-nav {
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  color: hsla(0, 0%, 100%, 0.55);
  background: #009688;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0 15px;
  box-sizing: border-box;
}

.order-nav li {
  padding: 0 12px;
  list-style: none;
  font-family: "Arimo", sans-serif;
}

.order-nav li:hover {
  cursor: pointer;
}

.active-tab {
  border-bottom: 4px solid #fff;
  color: #fff !important;
}

.content {
  padding: 20px;
  box-sizing: border-box;
  background: #fafafa;
}

.content-con {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14),
    0 0 0 0 rgba(0, 0, 0, 0.12);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  background: #fff;
  border-bottom: 1px solid #a6a6a6;
  margin-bottom: 15px;
}

.parity {
  width: 100%;
}

.parity p {
  font-size: 12px;
  text-align: center;
  padding: 15px 0px;
  color: rgb(136, 136, 136);
}

/* Promotion Page */
.promotion {
  background: #fafafa;
  padding-bottom: 64px;
}
.right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar {
  width: 25px;
  height: 25px;
  color: #fafafa;
}

.pro-cont {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.container {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  background: #fff;
}

.headline {
  padding: 15px;
  box-sizing: border-box;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

.level-box {
  background: #fff;
}

.level-list {
  padding: 15px;
  box-sizing: border-box;
}

.layout {
  width: 100%;
  display: flex;
  flex-direction: row;
  list-style: none;
}

.layout li {
  width: 50%;
}

.one-ol {
  text-align: center;
  font-size: 16px;
  color: #757575;
}

.two-ol {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.5;
  text-align: center;
}

.layout-bot {
  padding: 15px 0;
  position: relative;
}

.bot-list {
  border-bottom: 1px solid #949494;
  padding: 15px 0 12px;
}

.titles {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 5px;
}

.answer {
  font-size: 16px;
  font-weight: 400;
}

.height {
  height: 70px;
  line-height: 1.5;
}

.open-link {
  width: 100%;
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.open-link button {
  width: 50%;
  padding: 10px 15px;
  font-size: 14px;
  border: 0;
  background: #f5f5f5;
  border-radius: 2px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.success {
  width: fit-content;
  min-width: 96px;
  min-height: 0;
  padding: 8px 12px;
}

.message {
  position: fixed;
  top: 50%;
  left: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: content-box;
  width: 88px;
  max-width: 70%;
  min-height: 88px;
  padding: 16px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  text-align: center;
  word-wrap: break-word;
  background-color: rgba(50, 50, 51, 0.88);
  border-radius: 8px;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.tabs {
  position: relative;
}

.van-tab-wrap {
  height: 44px;
  overflow: hidden;
}

.van-tab-nav {
  box-sizing: content-box;
  height: 100%;
  padding-bottom: 15px;
  user-select: none;
  background-color: #fff;
  display: flex;
  position: relative;
}

.van-tab {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  flex: 1;
  box-sizing: border-box;
  padding: 0 4px;
  font-size: 14px;
  cursor: pointer;
}

.van-tab-new {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.active-tab-promo {
  color: #323233;
  font-weight: 500;
  background: #ddd;
}

.van-tab--pane {
  box-sizing: border-box;
  width: 100%;
}

.van-search {
  align-items: center;
  box-sizing: border-box;
  padding: 10px 12px;
  background-color: #fff;
  display: flex;
  -webkit-box-align: center;
}

.van-search-content {
  -webkit-box-flex: 1;
  flex: 1;
  padding-left: 8px;
  background-color: #f7f8fa;
  border-radius: 2px;
}

.van-cell {
  -webkit-box-flex: 1;
  flex: 1;
  padding: 5px 8px 5px 0;
  background-color: transparent;
  display: flex;
  box-sizing: border-box;
  width: 100%;
}

.van-field-left-icon {
  margin-right: 5px;
}

.van-cell-value {
  overflow: visible;
  vertical-align: middle;
  word-wrap: break-word;
  color: #323233;
  text-align: left;
  position: relative;
}

.van-field-body {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.van-field-control {
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-width: 0;
  margin: 0;
  padding: 0;
  color: #323233;
  line-height: inherit;
  text-align: left;
  background-color: transparent;
  border: 0;
  resize: none;
}

.van-field-body input {
  border-color: transparent;
  border: 0;
}

.child-items {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  background: #fff;
  border-top: 1px solid #ddd;
}

.child-item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  flex: 1;
  padding: 12px 0;
}

.pagination {
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
}

.pagination button {
  background-color: transparent;
  border: none;
}

/* .pagination button .left {
  margin-right: 20px;
}

.pagination button .right {
  margin-left: 20px;
} */

.page-box {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  list-style: none;
}

.page {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}

.page-btn {
  width: 36%;
  display: flex;
  justify-content: space-between;
}

.My-Order ul {
  display: flex;
  justify-content: space-between;
  padding: 0px;
  padding-right: 15px;
  font-family: "Arimo", sans-serif;
}

.My-Order ul li {
  display: flex;
  padding: 5px;
  font-family: "Arimo", sans-serif;
}

.My-Order ul li ol {
  justify-content: space-between;
  font-family: "Arimo", sans-serif;
}

.drop {
  flex-direction: column;
}

.drop li {
  display: flex;
  justify-content: space-between;
}

/* Red Envelope */
.red-box {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.null-data {
  height: 33px;
  line-height: 33px;
  font-size: 12px;
  text-align: center;
}

.choose-page {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}

.choose-page-par {
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.choose-page-par span {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 15px;
}

.page-box-two {
  width: 20%;
  border-bottom: 1px solid #949494;
}

.van-dropdown-menu {
  user-select: none;
}

.van-dropdown-menu-bar {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 48px;
  background-color: #fff;
  box-shadow: 0 2px 12px rgba(100, 101, 102, 0.08);
}

.van-dropdown-menu-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  min-width: 0;
  cursor: pointer;
  border: none;
}

.van-dropdown-menu-title {
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  padding: 0 8px;
  color: #323233;
  font-size: 15px;
  line-height: 22px;
  border: 0;
}

.van-collapse-item-wrapper {
  overflow: hidden;
  -webkit-transition: height 0.3s ease-in-out;
  transition: height 0.3s ease-in-out;
  will-change: height;
}

/* chat gpt */

.bank-card {
  background: #fafafa;
  padding-bottom: 64px;
}

.address-list {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background: #fff;
}

.list-ul li {
  padding: 15px 10px 15px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
}

.left-icon {
  width: 18%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center {
  width: 72%;
}

.name {
  font-size: 16px;
}

.info-text {
  font-size: 14px;
  color: #757575;
  margin-top: 5px;
}

.right-icon {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Add Bank card */

.AddBankcard {
  background: #fafafa;
  padding-bottom: 64px;
}

.input-card {
  width: 100%;
  padding: 15px 15px 64px 15px;
  box-sizing: border-box;
}

.card-ul li {
  padding: 15px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
}

.card-ul li p {
  font-size: 12px;
  color: #7d7d7d;
  margin-bottom: 10px;
  font-family: "Arimo", sans-serif;
}

.card-ul li input {
  background: transparent;
  font-size: 16px;
  border: 0;
  border-bottom: 1px solid #919191;
}

.go-code {
  height: 46px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 0 12px;
  line-height: 46px;
  border-radius: 2px;
  border: 0;
  text-align: center;
}

.continue-btn {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.continue-btn button {
  width: 65%;
  padding: 12px 0;
  text-align: center;
  border: 0;
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  font-family: "Arimo", sans-serif;
  background: #009688;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

/* complaint */

.Completed-nav {
  display: flex;
  flex-direction: row;
  padding: 20px 20px;
  box-sizing: border-box;
  background: #009688;
  position: relative;
}

.active-tab-c {
  padding: 0 4px !important;
  border-bottom: 4px solid #fff;
  color: #fff !important;
}

.completed-nav {
  display: flex;
  flex-direction: row;
  padding: 20px 20px;
  box-sizing: border-box;
  background: #009688;
  position: relative;
  list-style: none;
}

.completed-nav li {
  width: 50%;
  text-align: center;
  color: #fff;
  font-size: 15px;
}

.dropdown {
  width: 100%;
}

.dropdown button {
  width: 100%;
  background-color: white;
  color: #000000;
  border: none;
}

.dropdown-menu {
  width: 100%;
}

.dropdown button:hover {
  background-color: white;
  color: #000000;
}

/* win */
.mine-top {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.mine-info {
  width: 100%;
  background: #009688;
  border-radius: 2px;
  padding: 12px 8px 8px 8px;
  box-sizing: border-box;
}

.balance {
  padding: 12px 8px 15px;
  box-sizing: border-box;
  font-size: 18px;
  color: #fff;
  font-family: "Arimo", sans-serif;
}

.mine-info-btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.btn-win button {
  padding: 0 15px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  border: 0;
  border-radius: 2px;
  background: #f5f5f5;
  margin-right: 10px;
}

.main-nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
}

.main-nav li {
  flex: 1;
  font-size: 14px;
  font-family: "Arimo", sans-serif;
  height: 48px;
  line-height: 48px;
  text-align: center;
  opacity: 0.7;
  cursor: pointer;
}

.active-tab-win {
  opacity: 1;
  border-bottom: 2px solid #009688;
  color: #000000;
  font-weight: 900;
}

.center-text {
  width: 100%;
  padding: 15px 0 5px;
  background: #fafafa;
}

.center-top {
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.center-top li {
  width: 50%;
  list-style: none;
}

.top-ol {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  color: #7d7d7d;
}

.bot-ol {
  margin-top: 12px;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Arimo", sans-serif;
}

.right-li {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.btn-center {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px 15px;
  box-sizing: border-box;
  margin-top: 12px;
}

.btn-center button {
  padding: 10px 16px;
  font-size: 14px;
  font-family: "Arimo", sans-serif;
  border-radius: 2px;
  border: 0;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
}

.back-one {
  background: #4caf50;
  color: #fff;
}

.back-two {
  background: #9c27b0;
  color: #fff;
}

.back-three {
  background: #f44336;
  color: #fff;
}

.disable {
  background: rgb(184, 184, 184) !important;
  color: black !important;
}

.center-notes {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 8px;
  box-sizing: border-box;
  list-style: none;
  font-family: "Arimo", sans-serif;
}

.center-notes li {
  width: 20%;
  display: flex;
  justify-content: center;
}

.center-notes li ol {
  display: inline-block;
  padding: 10px 0;
  width: 80%;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border-radius: 2px;
  background: #2196f3;
  margin-bottom: 15px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  cursor: pointer;
}

.refresh {
  cursor: pointer;
}

.content {
  background: #fafafa;
}

.content-con {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14),
    0 0 0 0 rgba(0, 0, 0, 0.12);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  background: #fff;
  border-bottom: 1px solid #a6a6a6;
  margin-bottom: 15px;
}

.content-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #009587;
  padding: 12px 0;
}

.content-title p {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-family: "Arimo", sans-serif;
  margin-top: 10px;
}

.lenier {
  background: linear-gradient(
    to right bottom,
    rgb(156, 39, 176) 50%,
    rgb(244, 67, 54) 50%
  ) !important;
  font-family: "Arimo", sans-serif;
}

.lenier1 {
  background: linear-gradient(
    to right bottom,
    rgb(156, 39, 176) 50%,
    rgb(76, 175, 80) 50%
  ) !important;
}

.parity ul li {
  list-style: none;
}

.head {
  width: 20%;
  height: 56px;
  line-height: 56px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  font-family: "Arimo", sans-serif;
  text-align: center;
}

.pa-ul {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #e0e0e0;
}

.pa-li {
  height: 48px;
  line-height: 48px;
  font-size: 12px;
  font-family: "Arimo", sans-serif;
  text-align: center;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #e0e0e0;
}

.no-data {
  font-size: 12px;
  text-align: center;
  padding: 15px 0px;
  color: rgb(136, 136, 136);
}

.paginate {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: "Arimo", sans-serif;
}

.order-btn {
  padding: 0 15px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0/20%), 0 2px 2px 0 rgb(0 0 0/14%),
    0 1px 5px 0 rgb(0 0 0/12%);
  color: rgba(0, 0, 0, 0.87);
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  border: 0;
  border-radius: 2px;
  background: #f5f5f5;
  margin-right: 10px;
}

.odr {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Recharge */

.recharge-box {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.top-txt {
  font-size: 24px;
  font-weight: 400;
  padding: 15px;
  box-sizing: border-box;
  text-align: center;
  font-family: "Arimo", sans-serif;
}

.code-input-box {
  width: 100%;
  display: flex;
  justify-content: center;
  border: none;
}

.code-input {
  width: 95%;
  height: 48px;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.code-input input {
  width: 90%;
  font-size: 14px;
  border: 0;
}

.amount-list {
  width: 100%;
  padding: 25px 10% 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.amount-list button {
  width: 28%;
  margin: 5px 5px;
  padding: 10px 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  background: #f5f5f5;
  border: 0;
}

.payment-box {
  width: 100%;
  padding-left: 4%;
  box-sizing: border-box;
}

.payment-text {
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  color: #757575;
  font-family: "Arimo", sans-serif;
}

.van-radio {
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.van-icon {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  flex: none;
  height: 1em;
  font-size: 20px;
  line-height: 1em;
  cursor: pointer;
}

.van-lebel {
  margin-left: 8px;
  color: #323233;
  line-height: 20px;
}

.text {
  margin-left: 30px;
  display: inline-block;
  color: #212121;
}

.recharge-btn {
  width: 100%;
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0 24px;
  font-family: "Arimo", sans-serif;
}

.recharge-btn button {
  background: #009688;
  color: #fff;
  font-size: 14px;
  border-radius: 2px;
  border: 0;
  padding: 14px 0;
  text-align: center;
  width: 52%;
  font-family: "Arimo", sans-serif;
}

/* Trend */
.van-nav-bar {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 46px;
  line-height: 1.5;
  text-align: center;
  background-color: #fff;
  -webkit-user-select: none;
  user-select: none;

  border-bottom-width: 1px;
}

.van-nav-bar-left {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 16px;
  font-size: 14px;
  cursor: pointer;
  left: 0;
}

.van-nav-bar-center {
  max-width: 60%;
  margin: 0 auto;
  color: #323233;
  font-weight: 500;
  font-size: 16px;
}

.van-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.van-nav-bar-right {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 16px;
  font-size: 14px;
  cursor: pointer;
  right: 0;
}

.kline {
  padding: 5px 12px 0;
}

.reservation-chunk {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.reservation-chunk-sub {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.reservation-chunk-sub-title {
  padding: 10px 0;
  font-size: 16px;
  color: #7d7d7d;
}

.reservation-chunk-sub-num {
  font-size: 24px;
}

.item-green {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  text-align: center;
  line-height: 19px;
  font-size: 12px;
  color: #fff;
  background: #157239;
}

.title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  margin: 10px;
}

.kline .title .red {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #f52827;
}

.kline .title .green {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #157239;
}

.kline .title .voilet {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #e839f1;
}

.van-button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 44px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.van-button-small {
  height: 32px;
  padding: 0 8px;
  font-size: 12px;
}

.switchbox {
  float: right;
  margin: 10px;
}

.kline .box {
  overflow: scroll;
  width: 100%;
  line-height: 40px;
}

.box .grid {
  display: inline-grid;
  width: auto;
  padding-bottom: 20px;
  border: 1px solid #eee;
}

.kline .box .row {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: 40px;
  border-bottom: 1px solid #eee;
}

.row .cell {
  padding: 10px;
  border-left: 1px solid #eee;
}

.text-field {
  padding: 30px 15px 15px;
  box-sizing: border-box;
  font-size: 14px;
  color: #212121;
  font-family: "Arimo", sans-serif;
}

.payment-box-w {
  width: 100%;
  padding-left: 4%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.payment-box-w .payment-text {
  height: 38px;
  line-height: 38px;
  font-size: 14px;
  color: #757575;
  font-family: "Arimo", sans-serif;
}

.add-card {
  margin-bottom: 5px;
}

.select select {
  width: 100%;
  border: none;
}

.custom-dropdown {
  position: relative;
  background-color: #fff;
  width: 100%;
  padding: 8px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
  width: 200px;
  max-height: 20;
  z-index: 1;
  transition: max-height 0.3s ease-in-out;
  margin-bottom: 16px;
}

.custom-dropdown.open {
  border-radius: 4px 4px 0 0;
  width: 99%;
  max-height: 2000px;
}

.selected-option {
  color: #333;
  font-family: "Arimo", sans-serif;
}

.options-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: none;
  border-radius: 0 0 4px 4px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
  padding: 0;
  margin: 0;
  list-style: none;
}

.options-list li {
  padding: 8px;
  color: #333;
  cursor: pointer;
}

.options-list li:hover {
  background-color: #f2f2f2;
}

.password-box {
  padding: 15px;
  box-sizing: border-box;
}

.box-border {
  border-bottom: 1px solid #949494;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
}

.box-border input {
  width: 90%;
  border: 0;
  font-size: 14px;
}

input {
  outline: none;
}

.options-list Link {
  text-decoration: none !important;
}

.decor {
  text-decoration: none;
}

.Info {
  padding: 0 15px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: "Arimo", sans-serif;

  line-height: 1.5;
}

.Price {
  padding: 8px 15px;
  box-sizing: border-box;
  font-family: "Arimo", sans-serif;
}

.swiper-box {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding-top: 10px;
  box-sizing: border-box;
}

.van-swipe {
  position: relative;
  overflow: hidden;
  cursor: grab;
  -webkit-user-select: none;
  user-select: none;
}

.my-swipe {
  height: 490px;
  width: 100%;
  background: #fafafa;
}

.table-info table {
  background: #fff;
  width: 100%;
}

.table-info table thead tr th {
  width: 50%;
  padding: 0 15px 0 8%;
  box-sizing: border-box;
  text-align: left;
  line-height: 1.5;
  font-family: "Arimo", sans-serif;
}

.table-info table tbody tr td {
  width: 50%;
  padding: 0 15px 0 8%;
  box-sizing: border-box;
  text-align: left;
  line-height: 1.5;
  font-family: "Arimo", sans-serif;
}

table tbody tr {
  height: 56px;
  line-height: 56px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 12px;
}

/* win modal */

.branch-ttl {
  padding: 15px;
  box-sizing: border-box;
  font-size: 24px;
  font-family: "Arimo", sans-serif;
  color: #fff;
}

.branch-content {
  padding: 20px 30px 10px 30px;
  box-sizing: border-box;
}

.money {
  margin-bottom: 15px;
  font-size: 14px;
  font-family: "Arimo", sans-serif;
  color: #212121;
}

.choose-money {
  margin-bottom: 15px;
  display: flex;
  font-family: "Arimo", sans-serif;
}

.choose-money ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  border-radius: 2px;
  overflow: hidden;
  justify-content: space-between;
  list-style: none;
}

.choose-money ul li {
  padding: 10px 10px;
  font-size: 14px;
  font-family: "Arimo", sans-serif;
  color: #a6a6a6;
}

.active-money {
  background: #c9c9c9;
  color: #333;
}

.stepper {
  margin-bottom: 40px;
}

.van-stepper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.van-stepper button {
  width: 56px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background: #f5f5f5;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  border: none;
}

.agree {
  margin: 25px 0 15px;
}

.check-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.van-checkbox-lbl {
  margin-left: 8px;
  color: #323233;
  line-height: 20px;
}

.van-checkbox-lbl Link {
  text-decoration: none !important;
  font-family: "Arimo", sans-serif;
}

.modal-buttons {
  height: 48px;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 15px;
  box-sizing: border-box;
  background: #fff;
}

.modal-buttons button {
  border: 0;
  background: transparent;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  margin: 0 10px 0 20px;
}

.transaction {
  width: 100%;
  font-family: "Arimo", sans-serif;
}

.history {
  margin: 2%;
}

.history li {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px;
  padding: 2px;
}

.history li p {
  font-size: 18px;
}

button:hover {
  cursor: pointer;
}

.list_img img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}

.list_img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}

.goshop_btn {
  padding: 8px 15px;
  box-sizing: border-box;
}

.goshop_btn button {
  padding: 10px 15px;
  border: 0;
  background: #f39839;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
}

.disabled_btn {
  background: #dcdcdc !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
