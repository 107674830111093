li a {
  border-radius: 7px;
  padding: 0.4rem 0.4rem;
  /* border: gray 1px solid; */
  cursor: pointer;
}
li.previous a,
li.next a,
li.break a {
  border-color: transparent;
  font-size: 14px;
}
li.active a {
  /* background-color: #0366d6; */
  border-color: transparent;
  color: rgb(0, 0, 0);
  /* min-width: 10px; */
}
li.disabled a {
  color: grey;
}
li.disable,
li.disabled a {
  cursor: default;
}
.active {
  /* margin-bottom: 2rem; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding-left: 5px;
  padding-right: 5px;
}

ul {
  list-style-type: none;
}
.page {
  display: flex;
  flex-direction: row;
}
